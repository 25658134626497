<template>
  <div class="project-settings-wrap">
    <section class="try-content">
      <section class="filter">
        <el-form :inline="true">
          <el-form-item label="处理状态">
            <el-select v-model="form.state" size="small" placeholder="请选择处理状态">
              <el-option label="全部" :value="null"></el-option>
              <el-option label="已处理" :value="1"></el-option>
              <el-option label="未处理" :value="0"></el-option>
              <el-option label="潜在客户" :value="2"></el-option>
              <el-option label="跟踪中" :value="3"></el-option>
              <el-option label="无效客户" :value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公司名称">
            <el-input v-model="form.company" size="small" placeholder="请输入公司名称"></el-input>
          </el-form-item>
          <el-form-item label="联系人">
            <el-input v-model="form.call" size="small" placeholder="请输入联系人名称"></el-input>
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input v-model="form.linkTelephone" size="small" placeholder="请输入联系电话"></el-input>
          </el-form-item>
          <el-form-item label="申请时间">
            <el-date-picker v-model="form.createDate" type="date" placeholder="选择创建时间"></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" @click="search" :disabled="loading">查询</el-button>
          </el-form-item>
        </el-form>
      </section>
      <section class="apply-list">
        <el-table :data="tableData" border v-loading="loading" ref="table" style="width: 100%">
          <el-table-column  :resizable="false" prop="call" align="center" label="联系人"></el-table-column>
          <el-table-column  :resizable="false" prop="linkTelephone" align="center" label="手机号"/>
          <!-- <el-table-column  :resizable="false" prop="email" align="center" label="邮箱地址"/> -->
          <el-table-column  :resizable="false" prop="company" align="center" label="公司名称"></el-table-column>
          <el-table-column  :resizable="false" prop="createDate" align="center" width="160px" label="申请时间">
            <template scope="{ row }">
                {{ dateFormat(row.createDate) }}
              </template>
            </el-table-column>
          <el-table-column  :resizable="false" prop="state" align="center" label="处理状态">
            <template scope="{ row }">
              <span :style="{ color: row.state === 1 ? '#2EDC90' : row.state === 2 ? '#909399' : row.state === 3 ? '#E6A23C': row.state === 4 ? '#FF0000' : '#C0C4CC' }">
                {{ row.state === 0 ?'未处理': row.state === 1 ?'已处理': row.state === 2 ?'潜在客户':row.state === 3 ?'跟踪中': '无效客户' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column  :resizable="false" prop="remarl" align="center" label="备注"></el-table-column>
          <el-table-column  :resizable="false" label="操作" align="center">
            <template scope="{ row }">
                <el-button type="text" @click="showDialog(row)">处理</el-button>
            </template>
          </el-table-column>
        </el-table>
        <section class="list-bottom">
          <span class="count">共{{count}}条记录</span>
          <el-pagination :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="size" layout="total, sizes, prev, pager, next, jumper"
                         @size-change="sizeChange" @current-change="currentChange" :total="count">
          </el-pagination>
        </section>
      </section>
    </section>
    <el-dialog title="处理" :visible.sync="dialogVisible" width="40%">
      <el-form ref="form" label-width="80px">
        <el-form-item label="处理状态">
          <el-select v-model="state" size="small" placeholder="请选择处理状态">
            <el-option label="已处理" :value="1"></el-option>
            <el-option label="潜在客户" :value="2"></el-option>
            <el-option label="跟踪中" :value="3"></el-option>
            <el-option label="无效客户" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="remark" size="small" placeholder="请输入处理备注" type="textarea" :rows="4"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleFun">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getApplyList,dealApply } from '@/api/settings/apply'
export default {
  mounted () {
    this.getList()
  },
  data () {
    return ({
      count: 0,
      currentPage: 1,
      size: 10,
      loading:false,
      dialogVisible:false,
      tableData: [],
      state:'',
      remark:'',
      form:{
        company:'',
        state:null,
        createDate:'',
        call:'',
        linkTelephone:''
      }

    })
  },
  methods: {
    dateFormat (date) {
      if(date)  date = date.replace(/-/g,"/").substr(0,date.indexOf('.'))
      var t = new Date(date)
      var year=t.getFullYear(),
     　　 month=t.getMonth()+1,
     　　 day=t.getDate(),
    　　  hour=t.getHours(),
    　　  min=t.getMinutes(),
    　　  sec=t.getSeconds()
　　  var newTime = year + '-' +
    　　  (month < 10 ? '0'+ month : month) + '-' +
   　　   (day <10 ? '0' + day : day) + ' ' +
    　　  (hour <10 ? '0' + hour : hour) + ':' +
    　　  (min <10 ? '0' + min : min) + ':' +
    　　  (sec <10 ? '0' + sec : sec)
　　  return newTime
    },
    showDialog(row) {
      this.dialogVisible = true
      this.id = row.id
      this.state = ''
      this.remark = ''
    },
    async handleFun() {
      this.dialogVisible = false
      const res = await dealApply({ id:this.id,state:this.state,remarl:this.remark })
      if(res.code == 0){
        this.$message.success('处理成功')
      }else{
        this.$message.error('处理失败')
      }
    },
    search(){
      this.currentPage = 1
      this.getList()
    },
    async getList () {
      this.loading = true
      const res = await getApplyList({ current: this.currentPage, pageSize: this.size, company: this.form.company, state:this.form.state,createDate:this.form.createDate,call:this.form.call,linkTelephone:this.form.linkTelephone })
      this.tableData = res.data
      this.count = res.count
      this.loading = false
    },

    sizeChange: async function (i) {
      this.loading = true
      this.currentPage = 1
      this.size = i
      this.getList()
    },
    currentChange: async function (i) {
      this.loading = true
      this.currentPage = i
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
  .project-settings-wrap {
    width: 100%;
    min-height: 100%;
    .filter {
      width: 100%;
      // height: 70px;
      background: #fff;
      border-radius: 2px;
      padding-top: 15px;
      padding-left: 20px;
      box-shadow: 0px 0px 6px 0px rgba(53, 64, 82, 0.06);
    }
    .apply-list {
      width: 100%;
      margin-top: 20px;
      min-height: 100%;
      background: #fff;
      border-radius: 2px;
      padding: 20px 20px;
      box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
      position: relative;
      .btn-wrap {
        margin-bottom: 25px;
      }

      .list-bottom {
        display: flex;
        justify-content: space-between;
        .count {
          line-height: 32px;
        }
      }
      .selected {
        background: rgba(64,158,255, .2);
      }

    }
    .el-select{
      width: 100%;
    }
  }
</style>
