import request from '@/utils/request'

// 申请试用
export function submitApply (data) {
  return request({
    url: '/retainCapital/saveRetainCapital',
    method: 'POST',
    data
  })
}

// 获取试用列表
export function getApplyList (data) {
  return request({
    url: '/retainCapital/getRetainCapital',
    method: 'POST',
    data
  })
}
// 处理
export function dealApply (data) {
  return request({
    url: '/retainCapital/dealWithretainCapital',
    method: 'POST',
    data
  })
}
